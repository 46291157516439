import { validationToString, validationToStringOrNull, validationToUnitTypesArray } from "./validations";

const statusesKeys = [
    { key: 'id' ,validation:validationToString },
    { key: 'nameRU' ,validation:validationToString },    { key: 'descriptionRU',validation:validationToString },
    { key: 'nameEN' ,validation:validationToString },    { key: 'descriptionEN',validation:validationToString },
    { key: 'mainStatus' ,validation:validationToStringOrNull },
    { key: 'owners' ,validation:validationToUnitTypesArray },
    { key: 'fuse_statuses' ,validation:validationToStringOrNull }
];

export {statusesKeys}

export const normalizeKeys = (data: any): any => {
    return {
        id: data.id,
        nameRU: data.nameRu,
        descriptionRU: data.descriptionRu,
        nameEN: data.nameEn,
        descriptionEN: data.descriptionEn,
        mainStatus: data.main_status,
        owners: data.owners,
        fuse_statuses: data.fuse_statuses
    };
};